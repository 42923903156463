import { v4 as uuidv4 } from "uuid";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class Checkbox extends React.Component {
    constructor(props) {
        super(props);
        this.id = props.id || uuidv4();
        this.setRef = this.setRef.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setIndeterminate();
    }

    componentDidUpdate() {
        this.setIndeterminate();
    }

    onChange(event) {
        this.props.onChange(event.target.checked);
    }

    setRef(node) {
        this.node = node;
    }

    setIndeterminate() {
        const { indeterminate } = this.props;
        this.node.indeterminate = indeterminate;
    }

    renderInput() {
        const { disabled, checked, dataTestId } = this.props;
        return (
            <input
                id={this.id}
                name={this.id}
                type="checkbox"
                disabled={disabled}
                checked={checked}
                onChange={this.onChange}
                ref={this.setRef}
                data-test-id={dataTestId}
            />
        );
    }

    render() {
        const {
            title,
            tooltip,
            disabled,
            isInline,
            extraLabelClass,
            extraWrapperClass,
        } = this.props;
        return title ? (
            <div
                className={classNames({
                    checkbox: !isInline,
                    [extraWrapperClass]: extraWrapperClass,
                    "checkbox-inline": isInline,
                })}
                title={tooltip}
            >
                <label
                    htmlFor={this.id}
                    className={classNames({
                        "u-color-mute": disabled,
                        [extraLabelClass]: extraLabelClass,
                    })}
                >
                    {this.renderInput()}
                    {title}
                </label>
            </div>
        ) : (
            this.renderInput()
        );
    }
}

Checkbox.defaultProps = {
    id: null,
    dataTestId: null,
    title: null,
    disabled: false,
    indeterminate: false,
    isInline: false,
    tooltip: "",
    extraLabelClass: "",
    extraWrapperClass: "",
};

Checkbox.propTypes = {
    id: PropTypes.string,
    dataTestId: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    indeterminate: PropTypes.bool,
    tooltip: PropTypes.string,
    extraLabelClass: PropTypes.string,
    extraWrapperClass: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    isInline: PropTypes.bool,
};

export default Checkbox;
