import React from "react";
import { createRoot } from "react-dom/client";

import { initAxios } from "~/shared/utils";

import App from "./App";

initAxios();

const container = document.getElementById("global-search");
const root = createRoot(container);

const { dataset } = container || {};
const { globalSearchItems } = dataset || {};
const initialItems = globalSearchItems ? JSON.parse(globalSearchItems) : [];

root.render(<App initialItems={initialItems} />);
