import React from "react";
import PropTypes from "prop-types";

import GlobalSearch from "./components/GlobalSearch";

const App = ({ initialItems = [] }) => (
    <GlobalSearch initialItems={initialItems} />
);

App.propTypes = {
    initialItems: PropTypes.arrayOf(PropTypes.shape({})),
};

export default App;
