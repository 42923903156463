import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.less";

const Swimmer = ({ item }) => {
    const { location, team, name } = item;
    const meta = team ? `${team}, ${location}` : location;

    return (
        <div className={styles.item}>
            <div className={styles.itemBody}>
                <div className={styles.itemTitle}>{name}</div>
                <div className={styles.itemMeta}>{meta}</div>
            </div>
        </div>
    );
};

Swimmer.propTypes = {
    item: PropTypes.shape({
        location: PropTypes.string,
        team: PropTypes.string,
        name: PropTypes.string,
    }).isRequired,
};

export default Swimmer;
