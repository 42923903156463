import React, { useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";

import Checkbox from "~/shared/components/Checkbox";

import styles from "./BulkBar.less";

const AllCheckbox = ({ selection, onSelectAllInPage, onClearAll }) => {
    const { indeterminate, checked } = selection || {};
    const tooltip = indeterminate || checked ? "Clear all" : "Select all";
    const name = useRef(`bulk-checkbox-all-${uuidv4()}`);

    const onChange = () => {
        if (indeterminate || checked) onClearAll();
        else onSelectAllInPage();
    };

    return (
        <div className={styles.checkbox}>
            <Checkbox
                id={name.current}
                tooltip={tooltip}
                indeterminate={indeterminate}
                checked={checked}
                onChange={onChange}
                dataTestId="bulk-checkbox-all"
            />
        </div>
    );
};

AllCheckbox.propTypes = {
    selection: PropTypes.shape({}).isRequired,
    onSelectAllInPage: PropTypes.func.isRequired,
    onClearAll: PropTypes.func.isRequired,
};

export default AllCheckbox;
