import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { gtag, install } from "ga-gtag";
import pluralize from "pluralize";

import { captureError, getNoOptionsMessage } from "~/shared/utils";

import Option from "./Option";
import DropdownIndicator from "./DropdownIndicator";

const ITEM_TYPES = {
    Countrys: { orderNumber: 1, groupAlias: "Countries" },
    States: { orderNumber: 2 },
    Lscs: { orderNumber: 3 },
    Divisions: { orderNumber: 4 },
    Conferences: { orderNumber: 5 },
    "High school regions": { orderNumber: 6 },
    Teams: { orderNumber: 7 },
    Swimmers: { orderNumber: 8 },
    Meets: { orderNumber: 9 },
};

class GlobalSearch extends React.Component {
    static groupItems(items) {
        const grouped = _.groupBy(items, "source");

        return _.toPairs(grouped).map(([key, options]) => {
            const type = ITEM_TYPES[key] || {};
            const { orderNumber, groupAlias } = type;

            return {
                label: groupAlias || key,
                options,
                orderNumber,
            };
        });
    }

    constructor(props) {
        super(props);
        this.state = this.getInitialState();

        this.searchCache = {};

        this.onInputChange = this.onInputChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getItems = this.getItems.bind(this);
    }

    getInitialState() {
        return { menuIsOpen: false, inputValue: "" };
    }

    componentDidMount() {
        // initialize gtag
        install("G-V8NLDKPXH3");
    }

    handleFocus() {
        if (this.props.initialItems.length) {
            this.setState({ menuIsOpen: true, inputValue: "" });
        }
    }

    handleBlur() {
        this.setState({ menuIsOpen: false, inputValue: "" });
    }

    async onChange(item) {
        this.setState({ menuIsOpen: false });
        if (item && item.url) {
            gtag("event", "search", {
                search_term: this.state.inputValue,
            });

            gtag("event", "select_content", {
                content_type: pluralize.singular(item.source).toLowerCase(),
                item_id: item.id.split(".")[2],
                item_name: item.name,
            });

            try {
                await axios.post("/api/store_search_item/", {
                    item,
                });
            } catch (error) {
                if (axios.isCancel(error)) return;
                captureError(error);
            }

            window.location = item.url;
        }
    }

    onInputChange(value) {
        if (value) {
            this.setState({ menuIsOpen: true, inputValue: value });
        }

        if (!value && !this.props.initialItems.length) {
            this.setState({ menuIsOpen: false, inputValue: "" });
        }
    }

    getItems(input) {
        return new Promise((resolve, reject) => {
            clearTimeout(this.ajaxDelay);

            if (this.source) this.source.cancel();

            this.source = axios.CancelToken.source();

            this.ajaxDelay = setTimeout(async () => {
                if (input && input in this.searchCache) {
                    resolve(this.searchCache[input]);
                    return;
                }

                try {
                    const response = await axios.get("/api/search/", {
                        params: { q: input || null },
                        cancelToken: this.source.token,
                    });

                    const items = response.data;
                    const grouped = GlobalSearch.groupItems(items);

                    // we sort grouped items by the given number
                    grouped.sort((a, b) => a.orderNumber - b.orderNumber);
                    this.searchCache[input] = grouped;

                    resolve(grouped);
                } catch (error) {
                    if (axios.isCancel(error)) return;
                    captureError(error);
                    reject();
                }
            }, 600);
        });
    }

    render() {
        const mq = `@media (max-width: 640px)`;
        const borderRadius = 6;
        const customStyles = {
            container: (base) => ({
                ...base,
                maxWidth: "700px",
                width: "100%",
                flex: 1,
                lineHeight: "initial",
            }),
            indicatorSeparator: () => {},
            groupHeading: (base) => ({
                ...base,
                marginBottom: 0,
                display: "flex",
                alignItems: "center",
                height: 30,
                color: "#757575",
                fontSize: 12,
                letterSpacing: 1,
            }),
            control: (base, state) => ({
                ...base,
                borderWidth: 0,
                height: 45,
                minHeight: 45,
                borderRadius,
                backgroundColor: state.isFocused
                    ? "#fff"
                    : "rgba(0, 0, 0, 0.08)",
                boxShadow: state.isFocused
                    ? "0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08)"
                    : null,
            }),
            menu: (base) => ({
                ...base,
                borderRadius,
            }),
            valueContainer: (base) => ({
                ...base,
                overflow: "hidden",
                paddingLeft: 15,
            }),
            dropdownIndicator: (base) => ({
                ...base,
                paddingRight: 15,
                fontSize: 18,
                color: "rgba(0, 0, 0, .38) !important",
                [mq]: {
                    display: "none",
                },
            }),
            option: (base, state) => ({
                ...base,
                cursor: "pointer",
                backgroundColor: state.isFocused && "#eee",
                ":active": { backgroundColor: "#eee" },
            }),
        };

        const { initialItems } = this.props;
        let groupedItems = [];
        if (initialItems) {
            groupedItems = GlobalSearch.groupItems(initialItems);
        }

        return (
            <AsyncSelect
                inputId="global-search-select"
                defaultOptions={groupedItems}
                loadOptions={this.getItems}
                placeholder="Search"
                onChange={this.onChange}
                onInputChange={this.onInputChange}
                components={{ Option, DropdownIndicator }}
                loadingMessage={() => null}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                styles={customStyles}
                maxMenuHeight={670}
                menuIsOpen={this.state.menuIsOpen}
                noOptionsMessage={getNoOptionsMessage(
                    "Search...",
                    "No results found",
                )}
                autoFocus={this.props.autoFocus}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
            />
        );
    }
}

GlobalSearch.defaultProps = {
    autoFocus: false,
    initialItems: [],
};

GlobalSearch.propTypes = {
    autoFocus: PropTypes.bool,
    initialItems: PropTypes.arrayOf(PropTypes.shape({})),
};

export default GlobalSearch;
